import React, { useEffect } from 'react'

import { siteMetadata } from '../../gatsby-config'
import Layout from '../components/layout/layout'
import Meta from '../components/meta/meta'

interface Props {
  location: Location
}

const WorkWithUs: React.FC<Props> = ({ location }: Props) => {
  useEffect(() => {
    const typeformscript = document.createElement('script')
    typeformscript.async = true
    typeformscript.src = 'https://embed.typeform.com/embed.js'
    typeformscript.type = 'text/javascript'
    document.body.appendChild(typeformscript)

    const script = document.createElement('script')
    script.id = 'typescriptembed'
    script.async = true
    script.textContent = `
      function doTypeForm() {
        var el = document.getElementById("my-embedded-typeform");
        
        window.typeformEmbed.makeWidget(el, "https://admin.typeform.com/to/dYyAeafq?typeform-medium=embed-snippet", {
          hideFooter: true,
          hideHeaders: true,
          transparency: 50,
        });
      }
      if(window.typeformEmbed) {
        // console.log("already there, call it");
        doTypeForm();
      } else {
        // console.log("first time? or something.");
        window.addEventListener("DOMContentLoaded", doTypeForm);
      }
    `

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
      document.body.removeChild(typeformscript)
    }
  })

  return (
    <Layout location={location}>
      <Meta site={siteMetadata} title="Apply to Work With Us." />
      <div className="expand">
        <section className="text-center">
          <div className="container">
            <style type="text/css">
              {`
              div.typeform-widget {
                width: 100%; 
                height: 500px;
              }
            `}
            </style>
            <div
              id="my-embedded-typeform"
              className="typeform-widget"
              data-url="https://form.typeform.com/to/dYyAeafq?typeform-medium=embed-snippet"
              data-transparency="50"
              data-hide-headers="true"
              data-hide-footer="true"
            ></div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default WorkWithUs
